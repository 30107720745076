import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useListContext, useNotify } from 'react-admin';
import { API_URL } from '../../dataProvider';
import { DreamstimeAccount } from '../../types/DreamstimeAccount';
import { extractProxyDetails } from '../../utils/string';

interface CheckProps {
  children?: ReactNode;
}

export const CheckContext = createContext<{ id: string; proxyStatus: string; authStatus?: string }[]>([]);

export function CheckContextProvider({ children }: CheckProps) {
  // Initialize state
  const { data } = useListContext<DreamstimeAccount>();

  const [checkData, setCheckData] = useState<{ id: string; proxyStatus: string; authStatus?: string }[]>([]);

  const notify = useNotify();

  const test = useCallback(
    async (acc: DreamstimeAccount) => {
      try {
        const url = acc.proxy_url;
        // Extract protocol
        const { username, password, protocol, url: proxyUrl } = extractProxyDetails(url || '');

        const resp = await fetch(
          API_URL +
            '/check/proxy' +
            '?' +
            new URLSearchParams({
              username: username,
              password: password,
              proxy_url:
                password && username
                  ? acc.proxy_url.includes('://')
                    ? acc.proxy_url
                    : 'http://' + acc.proxy_url
                  : protocol + '://' + proxyUrl
            })
        );
        const dreamstimeCheckResp = await fetch(
          API_URL + '/check/dreamstime' + '?' + new URLSearchParams({ account_id: acc.id })
        );
        const dreamstimeCheckData = await dreamstimeCheckResp.json();
        const proxyCheckData = await resp.json();
        setCheckData((prev) => {
          return [
            ...prev.filter((dreamstimeAcc) => dreamstimeAcc.id !== acc.id),
            { proxyStatus: proxyCheckData.status, id: acc.id, authStatus: dreamstimeCheckData.status }
          ];
        });
      } catch (error) {
        notify('Error: proxy status check failed', { type: 'error' });
        console.error(error);
      }
    },
    [notify]
  );

  useEffect(() => {
    data?.forEach((acc) => {
      test(acc);
    });
  }, [test, data]);

  return <CheckContext.Provider value={checkData}>{children}</CheckContext.Provider>;
}

export function useCheckApi() {
  const context = useContext(CheckContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
