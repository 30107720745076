import React from 'react';
import ReactDOM from 'react-dom/client';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { BrowserTracing, init } from '@sentry/react';
import 'react-tooltip/dist/react-tooltip.css';
import './index.css';
import { App } from './App';

const faro = initializeFaro({
  url: 'https://faro-collector-prod-eu-west-0.grafana.net/collect/d93e2ade3f2882e32548006622656571',
  app: {
    name: 'aistocker-admin',
    version: '1.0.0',
    environment: 'production'
  },
  instrumentations: [
    // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
    ...getWebInstrumentations(),

    // Initialization of the tracing package.
    // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
    new TracingInstrumentation()
  ]
});
init({
  dsn: 'https://67888ae7f6b94a77a3ce9b5e850b56f2@o1124318.ingest.sentry.io/4504955902885888',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
