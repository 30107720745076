import { Datagrid, List, ListProps, NumberField, TextField } from 'react-admin';

export const PromptFilterList = (props: ListProps) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="sources" />
      <NumberField source="adobe_sales_count" />
    </Datagrid>
  </List>
);
