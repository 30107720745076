export const adobeCategories = [
  {
    id: 'Animals',
    upload_id: 10001,
    link: '/category/animals/1?load_type=category',
    name: 'Animals'
  },
  {
    id: 'Buildings and Architecture',
    upload_id: 10092,
    link: '/category/buildings-and-architecture/96?load_type=category',
    name: 'Buildings and Architecture'
  },
  {
    id: 'Business',
    upload_id: 10162,
    link: '/category/business/167?load_type=category',
    name: 'Business'
  },
  {
    id: 'Drinks',
    upload_id: 10209,
    link: '/category/drinks/214?load_type=category',
    name: 'Drinks'
  },
  {
    id: 'The Environment',
    upload_id: 10235,
    link: '/category/the-environment/240?load_type=category',
    name: 'The Environment'
  },
  {
    id: 'States of Mind',
    upload_id: 10255,
    link: '/category/states-of-mind/261?load_type=category',
    name: 'States of Mind'
  },
  {
    id: 'Food',
    upload_id: 10283,
    link: '/category/food/289?load_type=category',
    name: 'Food'
  },
  {
    id: 'Graphic Resources',
    upload_id: 10432,
    link: '/category/graphic-resources/444?load_type=category',
    name: 'Graphic Resources'
  },
  {
    id: 'Hobbies and Leisure',
    upload_id: 10486,
    link: '/category/hobbies-and-leisure/498?load_type=category',
    name: 'Hobbies and Leisure'
  },
  {
    id: 'Industry',
    upload_id: 10556,
    link: '/category/industry/568?load_type=category',
    name: 'Industry'
  },
  {
    id: 'Landscapes',
    upload_id: 10584,
    link: '/category/landscapes/596?load_type=category',
    name: 'Landscapes'
  },
  {
    id: 'Lifestyle',
    upload_id: 10631,
    link: '/category/lifestyle/643?load_type=category',
    name: 'Lifestyle'
  },
  {
    id: 'People',
    upload_id: 10683,
    link: '/category/people/695?load_type=category',
    name: 'People'
  },
  {
    id: 'Plants and Flowers',
    upload_id: 10733,
    link: '/category/plants-and-flowers/782?load_type=category',
    name: 'Plants and Flowers'
  },
  {
    id: 'Culture and Religion',
    upload_id: 10778,
    link: '/category/culture-and-religion/832?load_type=category',
    name: 'Culture and Religion'
  },
  {
    id: 'Science',
    upload_id: 10797,
    link: '/category/science/851?load_type=category',
    name: 'Science'
  },
  {
    id: 'Social Issues',
    upload_id: 10834,
    link: '/category/social-issues/888?load_type=category',
    name: 'Social Issues'
  },
  {
    id: 'Sports',
    upload_id: 10868,
    link: '/category/sports/922?load_type=category',
    name: 'Sports'
  },
  {
    id: 'Technology',
    upload_id: 10927,
    link: '/category/technology/981?load_type=category',
    name: 'Technology'
  },
  {
    id: 'Transport',
    upload_id: 10958,
    link: '/category/transport/1013?load_type=category',
    name: 'Transport'
  },
  {
    id: 'Travel',
    upload_id: 10988,
    link: '/category/travel/1043?load_type=category',
    name: 'Travel'
  }
];
