import { Button, FormControl, FormControlLabel, Switch, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { useListContext, useNotify, useUpdateMany } from 'react-admin';
import { DreamstimeAccount } from '../../types/DreamstimeAccount';

export const BulkDreamstimeAccountUpdate = () => {
  const { selectedIds, onUnselectItems } = useListContext();
  const [isAutoUpload, setIsAutoUpload] = useState(true);
  const [batchSize, setBatchSize] = useState(150);

  const [uploadLimit, setUploadLimit] = useState(150);

  const notify = useNotify();

  const [updateMany, { isLoading, error }] = useUpdateMany<DreamstimeAccount>(
    'dreamstime_accounts',
    {
      ids: selectedIds,
      data: { upload_enabled: isAutoUpload, upload_limit: uploadLimit }
    },
    {
      onSuccess: () => {
        console.log('success');

        notify('Accounts updated');
      },
      onError: (error) => notify('Error: accounts not updated', { type: 'error' }),
      mutationMode: 'optimistic'
    }
  );

  const handleUnselectAllClick = useCallback(() => {
    onUnselectItems();
  }, [onUnselectItems]);

  const handleConfirm = () => {
    updateMany();
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <FormControl className="flex ">
      <div className="flex gap-4 items-center justify-center">
        <TextField
          onChange={(e) => {
            setUploadLimit(Number(e.target.value));
          }}
          value={uploadLimit}
          id="standard-number"
          label="Upload limit"
          type="number"
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
        />
        <FormControlLabel
          control={
            <Switch
              onChange={() => {
                setIsAutoUpload(!isAutoUpload);
              }}
              checked={isAutoUpload}
              color="secondary"
            />
          }
          label="Auto Upload"
        />

        <Button
          onClick={() => {
            handleConfirm();
            handleUnselectAllClick();
          }}
          className="flex h-10"
          size="small"
          variant="contained"
          color="secondary">
          Confirm
        </Button>
      </div>
    </FormControl>
  );
};
