import {
  Create,
  CreateProps,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreateSuggestionContext
} from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { useState } from 'react';
import { adobeCategories } from './choices';

const choices: { id: string; name: string }[] = [];

const CreateKeyword = () => {
  const { onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = useState('');

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const newOption = { id: value, name: value };
    choices.push(newOption);
    setValue('');
    onCreate(newOption);
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField label="Keyword" value={value} onChange={(event) => setValue(event.target.value)} autoFocus />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const PromptCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput className="w-[400px]" source="prompt" label="Prompt" name="prompt" />
      <SelectArrayInput source="keywords" label="Keywords" choices={choices} create={<CreateKeyword />} />
      <SelectInput source="source" choices={[{ id: 'manual', name: 'manual' }]} />
      <SelectInput
        source="content_type"
        choices={[
          { id: 1, name: 'photo' },
          { id: 2, name: 'illustration' },
          { id: 3, name: 'vector' },
          { id: 4, name: 'video' }
        ]}
      />

      <SelectInput source="category" choices={adobeCategories} />
    </SimpleForm>
  </Create>
);
