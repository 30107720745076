import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useGetList, useListContext, useNotify, useUpdate } from 'react-admin';
import { AdobeAccount } from '../../types/AdobeStockAccount';
import { IPipeline } from '../../types/Pipeline';

interface AccountSelectProps {
  value?: string;
  source?: string;
}

export const BulkAccountSelect = (props: AccountSelectProps) => {
  const { data, isLoading, error } = useGetList<AdobeAccount>('adobe_stock_accounts', {
    pagination: { perPage: 300, page: 1 }
  });
  const { data: pipelines } = useGetList<IPipeline>('pipelines', {
    pagination: { perPage: 300, page: 1 }
  });

  const { selectedIds, onUnselectItems } = useListContext();
  const [update] = useUpdate<IPipeline>();
  const notify = useNotify();

  const [selectedAccount, setSelectedAccount] = useState<null | string>(null);

  const handleUnselectAllClick = useCallback(() => {
    onUnselectItems();
  }, [onUnselectItems]);
  const handleChange = (e: SelectChangeEvent<string>) => {
    setSelectedAccount(e.target.value);
  };
  const handleConfirm = (value: string) => {
    selectedIds.forEach((pipelineId) => {
      const pipeline = pipelines?.find((p) => p.id === pipelineId);
      if (!pipeline) return;
      update(
        'pipelines',
        {
          id: pipelineId,
          data: { ...pipeline, adobe_stock_account_id: value },
          previousData: pipeline
        },
        {
          onSuccess: () => {
            notify('Updated');
          },
          onError: () => {
            notify('Update failed', { type: 'error' });
          },
          mutationMode: 'optimistic'
        }
      );
    });
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <FormControl className="flex ">
      <div className="flex gap-4">
        <InputLabel id="demo-simple-select-label">Batch Update Account</InputLabel>
        <Select onChange={handleChange} className="w-44" label="Set Account">
          {data?.map((account) => {
            return (
              <MenuItem key={account.id} value={account.id}>
                {account.id}
              </MenuItem>
            );
          })}
        </Select>
        <Button
          onClick={() => {
            if (!selectedAccount) return;
            handleConfirm(selectedAccount);
            handleUnselectAllClick();
          }}
          className="flex"
          variant="contained"
          color="secondary">
          Confirm
        </Button>
      </div>
    </FormControl>
  );
};
