import { Create, CreateProps, SelectInput, SimpleForm, TextInput } from 'react-admin';

export const GeneratorOptionsCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="_id" label="Name" name="_id" />
      <TextInput source="description" label="Description" name="description" />
      <SelectInput
        source="generator_type"
        label="Generator Type"
        choices={[
          { id: 'mj test', name: 'mj test' },
          { id: 'sd', name: 'sd' }
        ]}
      />
      <SelectInput
        source="generator_version"
        label="Generator Version"
        choices={[
          { id: '--test', name: '--test' },

          { id: '--v 4', name: '--v 4' },
          { id: '--v 5', name: '--v 5' },
          { id: '--v 5.1', name: '--v 5.1' },
          { id: '--v 5.2', name: '--v 5.2' }
        ]}
      />
      <TextInput source="generator_options" label="Generator Options" name="generator_options" />
    </SimpleForm>
  </Create>
);
