import {
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin';
import { IPipeline } from '../../types/Pipeline';

export const PipelineCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput sx={{ minWidth: '400px' }} source="_id" label="Pipeline Unique ID" name="_id" />
      <BooleanInput defaultValue={true} source="enabled" name="enabled" />
      <BooleanInput defaultValue={true} source="resize_enabled" name="resize_enabled" />
      <TextInput sx={{ minWidth: '400px' }} source="resize_width" name="resize_width" />
      <TextInput sx={{ minWidth: '400px' }} source="resize_thumbnail_width" name="resize_thumbnail_width" />
      <BooleanInput defaultValue={true} source="manual_review_enabled" name="manual_review_enabled" />
      <BooleanInput defaultValue={true} source="dreamstime_uploader_enabled" name="dreamstime_uploader_enabled" />
      <BooleanInput defaultValue={true} source="freepik_uploader_enabled" name="freepik_uploader_enabled" />
      <BooleanInput defaultValue={true} source="adobe_stock_uploader_enabled" name="adobe_stock_uploader_enabled" />
      <BooleanInput defaultValue={true} source="adobe_stock_auto_upload " name="adobe_stock_auto_upload" />
      <BooleanInput defaultValue={false} source="reuse_other_pipelines_prompts" name="reuse_other_pipelines_prompts" />
      <BooleanInput defaultValue={true} source="randomize_prompts" name="randomize_prompts" />
      <TextInput
        defaultValue={'Generative AI'}
        sx={{ minWidth: '400px' }}
        source="adobe_stock_image_extra_title"
        name="adobe_stock_image_extra_title"
      />
      <BooleanInput
        defaultValue={true}
        source="adobe_stock_checkbox_ai_generated"
        name="adobe_stock_checkbox_ai_generated"
      />
      <SelectInput
        defaultValue={2}
        source="adobe_stock_content_type"
        choices={[
          { id: 1, name: 'Photos' },
          { id: 2, name: 'Illustrations' },
          { id: 3, name: 'Vectors' },
          { id: 4, name: 'Videos' }
        ]}
      />
      <ReferenceInput source="prompt_filter_id" reference="prompt_filters">
        <AutocompleteInput sx={{ minWidth: '400px' }} optionText={(record: any) => `${record.id} ${record.name}`} />
      </ReferenceInput>
      <ReferenceInput perPage={100} source="adobe_stock_account_id" reference="adobe_stock_accounts">
        <AutocompleteInput sx={{ minWidth: '400px' }} />
      </ReferenceInput>
      <BooleanInput defaultValue={true} source="generator_enabled" name="generator_enabled" />
      <ReferenceInput source="generator_options_id" reference="generator_options">
        <AutocompleteInput sx={{ minWidth: '400px' }} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
