import { List, ListProps, Pagination } from 'react-admin';
import { AdobeListDataGrid } from './AdobeListDataGrid';
import { CheckContextProvider } from './CheckContext';

export const AdobeStockAccountList = (props: ListProps) => (
  <List perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 300]} />} {...props}>
    <CheckContextProvider>
      <AdobeListDataGrid />
    </CheckContextProvider>
  </List>
);
