import { useListContext, useNotify, useRefresh, useUnselectAll, useUpdateMany } from 'react-admin';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { IImage } from '../../types/Image';

interface ApproveButtonProps {
  width?: string;
}

export const ApproveImagesButton = (props: ApproveButtonProps) => {
  const { width = '270px' } = props;
  const { selectedIds } = useListContext<IImage>();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('images');
  const [updateMany, { isLoading }] = useUpdateMany<IImage>(
    'images',
    { ids: selectedIds, data: { status: 'review-approved' } },
    {
      onSuccess: () => {
        console.log('success');
        refresh();
        notify('Images updated');
        unselectAll();
      },
      onError: (error) => notify('Error: images not updated', { type: 'error' })
    }
  );

  return (
    <Button sx={{ width }} color="primary" variant="contained" disabled={isLoading} onClick={() => updateMany()}>
      <CheckIcon />{' '}
      <span className="text-xs">
        Одобрить все <span className="text-base font-semibold">{selectedIds.length}</span> выбранные
      </span>
    </Button>
  );
};
