import {
  BooleanField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin';
import React from 'react';
import { ContentTypeMap, IPipeline } from '../../types/Pipeline';

export const PipelineShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <BooleanField source="enabled" />
      <NumberField source="weight" />
      <NumberField source="limit" />
      <ReferenceField source="prompt_filter_id" reference="prompt_filters">
        <FunctionField source="" render={(record: any) => `${record.id}: ${record.name}`} />
      </ReferenceField>
      <BooleanField source="resize_enabled" />
      <NumberField source="resize_width" />
      <NumberField source="resize_thumbnail_width" />
      <BooleanField source="manual_review_enabled" />
      <BooleanField source="dreamstime_uploader_enabled" />
      <BooleanField source="freepik_uploader_enabled" />
      <BooleanField source="adobe_stock_uploader_enabled" />
      <BooleanField source="adobe_stock_auto_upload" />
      <BooleanField source="reuse_other_pipelines_prompts" />
      <BooleanField source="randomize_prompts" />
      <TextField source="adobe_stock_image_extra_title" />
      <FunctionField
        source="adobe_stock_content_type"
        render={(record: IPipeline) => ContentTypeMap[record.adobe_stock_content_type]}
      />
      <BooleanField source="adobe_stock_checkbox_ai_generated" />
      <ReferenceField source="adobe_stock_account_id" reference="adobe_stock_accounts" />
      <BooleanField source="generator_enabled" />
      <ReferenceField source="generator_options_id" reference="generator_options" />
    </SimpleShowLayout>
  </Show>
);
