import { Edit, NumberInput, SelectArrayInput, SimpleForm, TextInput } from 'react-admin';
import { WordsInput } from '../../ui/WordsInput';
import { sources } from './sources';

export const PromptFilterEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="name" />
      <SelectArrayInput source="sources" choices={sources} />
      <NumberInput source="adobe_sales_count" label="Minimum adobe sales count" />
      <WordsInput source="whitelist_categories" />
      <WordsInput source="blacklist_categories" />
      <WordsInput source="blacklist_words" />
    </SimpleForm>
  </Edit>
);
