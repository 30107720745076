import { Card, Checkbox } from '@mui/material';
import { useCallback, useRef } from 'react';
import { ReferenceField, TextField, useListContext } from 'react-admin';
import { IImage } from '../../types/Image';
import { AppTooltip } from '../../ui/Tooltip';

import css from './Image.module.scss';

interface ImageCardProps {
  handleShiftToggleItem: any;
  record: IImage;
}

export const ImageCard = (props: ImageCardProps) => {
  const { selectedIds, onToggleItem, data, onSelect } = useListContext<IImage>();

  const checkbox = useRef<HTMLInputElement | null>(null);

  const handleShiftToggleSelection = useCallback(
    (event: any) => {
      props.handleShiftToggleItem(props.record.id, event);
      event.stopPropagation();
    },
    [props]
  );

  const handleClickSelection = useCallback(
    (event: any) => {
      if (event.shiftKey) {
        checkbox.current?.click();
      } else {
        onToggleItem(props.record.id);
      }

      event.stopPropagation();
    },
    [onToggleItem, props]
  );
  return (
    <>
      <Checkbox
        inputRef={checkbox}
        sx={{ display: 'none' }}
        onClick={handleShiftToggleSelection}
        checked={selectedIds?.includes(props.record.id)}></Checkbox>
      <div
        data-tooltip-id={props.record.id}
        className="wrapper"
        style={{ cursor: 'pointer' }}
        onClick={handleClickSelection}>
        {props.record.image_url || props.record.thumbnail_image_url ? (
          <img
            loading="lazy"
            style={{
              cursor: 'pointer',
              border: selectedIds.includes(props.record.id) ? '8px solid #2d8ceb' : '8px solid white'
            }}
            src={props.record.thumbnail_image_url || props.record.image_url}
          />
        ) : (
          <Card
            sx={{
              cursor: 'pointer',
              border: selectedIds.includes(props.record.id)
                ? { border: '8px solid #2d8ceb' }
                : { border: '8px solid white' }
            }}></Card>
        )}
      </div>
      <AppTooltip delay={2500} id={props.record.id}>
        <TextField className={css.black} source="prompt" />
      </AppTooltip>
    </>
  );
};
