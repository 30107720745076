import { List, ListProps, Pagination } from 'react-admin';
import { DreamstimeListDataGrid } from './DreamstimeListDataGrid';
import { CheckContextProvider } from './CheckContext';

export const DreamstimeAccountList = (props: ListProps) => (
  <List perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 300]} />} {...props}>
    <CheckContextProvider>
      <DreamstimeListDataGrid />
    </CheckContextProvider>
  </List>
);
