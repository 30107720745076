import { BooleanInput, Create, CreateProps, NumberInput, SimpleForm, TextInput } from 'react-admin';
import { extractProxyDetails, parseFormattedUrl } from '../../utils/string';

const getRandomValue = () => {
  const min = 100;
  const max = 300;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const AdobeStockAccountCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm sx={{ width: '100%' }}>
      <TextInput sx={{ minWidth: '400px' }} source="_id" label="ID" name="_id" />
      <TextInput
        sx={{ minWidth: '400px' }}
        source="dolphin_profile_id"
        label="Dolphin Profile ID"
        name="dolphin_profile_id"
      />
      <TextInput defaultValue={'en'} sx={{ minWidth: '400px' }} source="region" />
      <TextInput sx={{ minWidth: '400px' }} source="csrf_token" label="CSRF Token" name="csrf_token" />
      <TextInput sx={{ minWidth: '400px' }} source="cookies" label="Cookies" name="cookies" />
      <TextInput
        sx={{ minWidth: '400px' }}
        parse={(v) => {
          const newValue = parseFormattedUrl(extractProxyDetails(v)) || v;
          console.log(newValue);
          return newValue;
        }}
        source="proxy_url"
        label="Proxy URL or settings"
        name="proxy_url"
      />
      <TextInput sx={{ minWidth: '400px' }} source="username" label="Username" name="username" />
      <TextInput sx={{ minWidth: '400px' }} source="password" label="Password" name="password" />
      <NumberInput source="upload_limit" name="upload_limit" />
      <NumberInput source="upload_batch_size" name="upload_batch_size" />
      <BooleanInput defaultValue={true} source="auto_upload_enabled" name="auto_upload_enabled" />
      <NumberInput defaultValue={getRandomValue()} source="auto_upload_limit" name="auto_upload_limit" />
    </SimpleForm>
  </Create>
);
