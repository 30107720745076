import React, { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CancelIcon from '@mui/icons-material/Cancel';
import { BooleanField, Count, Datagrid, FunctionField, NumberField, TextField, useNotify } from 'react-admin';
import { FreepikAccount, FreepikAccountStatusMapping } from '../../types/FreepikAccount';
import { useCheckApi } from './CheckContext';
import { BulkFreepikAccountUpdate } from './BulkFreepikAccountUpdate';

export const FreepikListDataGrid = (props: any) => {
  const [copied, setCopied] = useState(false);
  const checkData = useCheckApi();
  const notify = useNotify();
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <Datagrid {...props} bulkActionButtons={<BulkFreepikAccountUpdate />} rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="upload_limit" />
      <FunctionField
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        source="proxy_url"
        render={(record: FreepikAccount) => (
          <CopyToClipboard
            onCopy={() => {
              setCopied(true);
              notify('proxy address copied');
            }}
            text={record.proxy_url}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <TextField source="proxy_url" />
              {record.proxy_url && <ContentCopyIcon />}
            </div>
          </CopyToClipboard>
        )}
      />

      <FunctionField
        source="Uploaded"
        render={(record: FreepikAccount) => {
          return (
            <>
              <Count resource="images" filter={{ freepik_account_id: record.id }} />
            </>
          );
        }}></FunctionField>
      <FunctionField
        source="Proxy status"
        render={(record: FreepikAccount) => {
          const status = checkData.find((data) => data.id === record.id)?.proxyStatus;
          if (!status) return <HourglassEmptyIcon />;
          if (status === 'ok') {
            return <CheckCircleIcon color="success" />;
          } else {
            return <CancelIcon color="error" />;
          }
        }}></FunctionField>
      <FunctionField
        source="Cookie status"
        render={(record: FreepikAccount) => {
          const status = checkData.find((data) => data.id === record.id)?.authStatus;
          if (!status) return <HourglassEmptyIcon />;
          if (status === 'ok') {
            return <CheckCircleIcon color="info" />;
          } else {
            return <CancelIcon color="error" />;
          }
        }}></FunctionField>
      <BooleanField source="upload_enabled" />
      <NumberField source="upload_limit" />
      <FunctionField
        label="Upload retry after"
        source="upload_paused_until"
        render={(record: FreepikAccount) => {
          const failures = record.upload_failure_counter;
          const currentTime = new Date();
          const pausedUntil = record.upload_paused_until ? new Date(record.upload_paused_until * 1000) : null;
          if (!pausedUntil) {
            return <span>-</span>;
          }
          let timeRemaining = pausedUntil.getTime() - currentTime.getTime();
          if (timeRemaining < 0) timeRemaining = 0;
          // Calculate the remaining time in hours and minutes
          const hoursRemaining = Math.floor(timeRemaining / (1000 * 60 * 60))
            .toString()
            .padStart(2, '0');
          const minutesRemaining = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
            .toString()
            .padStart(2, '0');
          return (
            <span>
              <p>
                ⌛{hoursRemaining}h:{minutesRemaining}m
              </p>
              <p>🔄 {failures}</p>
            </span>
          );
        }}></FunctionField>
      <FunctionField
        source="upload_status_message"
        render={(record: FreepikAccount) => {
          return (
            <div className="flex gap-1 items-center justify-center align-middle">
              <span>{FreepikAccountStatusMapping[record.upload_status_message ?? 'OK']}</span>
            </div>
          );
        }}></FunctionField>
    </Datagrid>
  );
};
