import { Datagrid, List, ListProps, NumberField, TextField } from 'react-admin';

export const PromptList = (props: ListProps) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="source" />
      <TextField source="prompt" />
      <TextField source="keywords" />
      <TextField source="title" />
      <TextField source="description" />
      <TextField source="content_type" />

      <NumberField source="adobe_sales_count" />
    </Datagrid>
  </List>
);
