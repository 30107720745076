import { BooleanField, FunctionField, NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { AdobeAccount } from '../../types/AdobeStockAccount';

export const AdobeStockAccountShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField sx={{ minWidth: '400px' }} source="id" />
      <TextField sx={{ minWidth: '400px' }} source="dolphin_profile_name" />
      <TextField sx={{ minWidth: '400px' }} source="status_message" />
      <FunctionField
        sx={{ minWidth: '400px' }}
        label="Cookies Updated At"
        render={(record: AdobeAccount) => {
          if (record.headers_update_datetime) return new Date(record.headers_update_datetime * 1000).toLocaleString();
        }}
      />
      <FunctionField
        sx={{ minWidth: '400px' }}
        label="Sales Updated At"
        render={(record: AdobeAccount) => {
          if (record.sales_update_date) return new Date(record.sales_update_date * 1000).toLocaleString();
        }}
      />
      <TextField sx={{ minWidth: '400px' }} source="dolphin_profile_id" />
      <TextField sx={{ minWidth: '400px' }} source="region" />
      <TextField sx={{ minWidth: '400px' }} source="csrf_token" />
      <FunctionField
        sx={{ minWidth: '400px' }}
        label="Cookies"
        render={(record: AdobeAccount) => record.cookies.slice(0, 100) + '...'}
      />
      <BooleanField source="auto_upload_enabled" />
      <NumberField source="auto_upload_limit" />
      <NumberField source="upload_limit" />
      <NumberField source="upload_batch_size" />
      <TextField sx={{ minWidth: '400px' }} source="proxy_url" />
      <TextField sx={{ minWidth: '400px' }} source="username" />
      <TextField sx={{ minWidth: '400px' }} source="password" />
    </SimpleShowLayout>
  </Show>
);
