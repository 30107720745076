import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const DreamstimeAccountEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput sx={{ minWidth: '400px' }} source="name" />
      <TextInput sx={{ minWidth: '400px' }} source="cookies" />
      <NumberInput source="upload_limit" />
      <BooleanInput sx={{ minWidth: '400px' }} source="upload_enabled" />
      <NumberInput sx={{ minWidth: '400px' }} source="upload_limit" />
      <TextInput sx={{ minWidth: '400px' }} source="proxy_url" />
      <TextInput sx={{ minWidth: '400px' }} source="username" />
      <TextInput sx={{ minWidth: '400px' }} source="password" />
    </SimpleForm>
  </Edit>
);
