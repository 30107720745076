import { ReactNode } from 'react';
import { Tooltip } from 'react-tooltip';

interface AppTooltopProps {
  id: string;
  delay?: number;
  children?: ReactNode;
  backgroundColor?: string;
}

export const AppTooltip = (props: AppTooltopProps) => {
  const { id, delay = 0, children } = props;
  return (
    <Tooltip
      clickable={true}
      style={{
        padding: '2px',
        paddingLeft: '8px',
        paddingRight: '8px',
        backgroundColor: props.backgroundColor ?? '#efefef'
      }}
      delayShow={delay}
      id={id}>
      {children}
    </Tooltip>
  );
};
