import { FirebaseAuthProvider } from 'react-admin-firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyApBPBnt2zvTUDfljAlcghH9ppZ-GLFZtU',
  authDomain: 'cryptosurfers.firebaseapp.com',
  projectId: 'cryptosurfers',
  storageBucket: 'cryptosurfers.appspot.com',
  messagingSenderId: '808780326375',
  appId: '1:808780326375:web:9fe101202a36c4bedbc942'
};

export const authProvider = FirebaseAuthProvider(firebaseConfig, {});
