import { ArrayInput, TextInput } from 'react-admin';

const wordsParser = (input: string) => {
  return input.split(',').map((word) => word.trim());
};
export const WordsInput = (props: any) => (
  <ArrayInput source={props.source}>
    <TextInput source="" parse={wordsParser} />
  </ArrayInput>
);
