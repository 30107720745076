import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin';

export const PipelineEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput sx={{ minWidth: '400px' }} source="id" />
      <BooleanInput sx={{ minWidth: '400px' }} source="enabled" />
      <NumberInput sx={{ minWidth: '400px' }} source="weight" />
      <NumberInput sx={{ minWidth: '400px' }} source="limit" />
      <ReferenceInput source="prompt_filter_id" reference="prompt_filters">
        <AutocompleteInput sx={{ minWidth: '400px' }} optionText={(record: any) => `${record.id}: ${record.name}`} />
      </ReferenceInput>
      <BooleanInput sx={{ minWidth: '400px' }} source="resize_enabled" />
      <NumberInput sx={{ minWidth: '400px' }} source="resize_width" />
      <NumberInput sx={{ minWidth: '400px' }} source="resize_thumbnail_width" />
      <BooleanInput sx={{ minWidth: '400px' }} source="manual_review_enabled" />
      <BooleanInput sx={{ minWidth: '400px' }} source="dreamstime_uploader_enabled" />
      <BooleanInput sx={{ minWidth: '400px' }} source="freepik_uploader_enabled" />
      <BooleanInput sx={{ minWidth: '400px' }} source="adobe_stock_uploader_enabled" />
      <BooleanInput defaultValue={true} source="adobe_stock_auto_upload" name="adobe_stock_auto_upload" />
      <BooleanInput sx={{ minWidth: '400px' }} source="reuse_other_pipelines_prompts" />
      <BooleanInput sx={{ minWidth: '400px' }} source="randomize_prompts" />
      <TextInput
        defaultValue={'Generative AI'}
        sx={{ minWidth: '400px' }}
        source="adobe_stock_image_extra_title"
        name="adobe_stock_image_extra_title"
      />
      <BooleanInput
        defaultValue={true}
        source="adobe_stock_checkbox_ai_generated"
        name="adobe_stock_checkbox_ai_generated"
      />
      <SelectInput
        defaultValue={2}
        source="adobe_stock_content_type"
        choices={[
          { id: 1, name: 'Photos' },
          { id: 2, name: 'Illustrations' },
          { id: 3, name: 'Vectors' },
          { id: 4, name: 'Videos' }
        ]}
      />
      <ReferenceInput perPage={300} source="adobe_stock_account_id" reference="adobe_stock_accounts">
        <AutocompleteInput sx={{ minWidth: '400px' }} />
      </ReferenceInput>
      <BooleanInput sx={{ minWidth: '400px' }} source="generator_enabled" />
      <ReferenceInput source="generator_options_id" reference="generator_options">
        <AutocompleteInput sx={{ minWidth: '400px' }} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
