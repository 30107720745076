import { Create, CreateProps, NumberInput, SelectArrayInput, SimpleForm, TextInput } from 'react-admin';
import { WordsInput } from '../../ui/WordsInput';
import { sources } from './sources';

export const PromptFilterCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Name" name="name" />
      <SelectArrayInput source="sources" choices={sources} />
      <TextInput source="whitelist_regex" label="Whitelist Regex" name="whitelist_regex" />
      <NumberInput defaultValue={0} source="adobe_sales_count" label="Minimum adobe sales count" />
      <WordsInput source="whitelist_categories" />
      <WordsInput source="blacklist_categories" />
      <WordsInput source="blacklist_words" />
    </SimpleForm>
  </Create>
);
