export interface FreepikAccount {
  id: string;
  name: string;

  username?: string;
  password?: string;

  cookies: string;
  proxy_url: string;

  upload_limit: number;
  upload_enabled?: boolean;
  upload_paused_until?: number;
  upload_failure_counter: number;
  upload_status_message?: keyof typeof FreepikAccountStatusMapping;
}

export const FreepikAccountStatusMapping = {
  OK: '🆗',
  'auto upload limit reached': '🏁 auto upload limit reached',
  'upload limit reached': '🏁 upload limit reached',
  'submit limit reached': '💤 submit limit reached',
  'proxy error': '❌🛡️ proxy error',
  'invalid csrf token error': '❗👩‍🦽👦🏾 invalid csrf token error',
  'invalid cookie error': '❗👩‍🦽🍪 invalid cookie error',
  'unknown upload error': '🚨 unknown upload error',
  'requires email verification': '📩 requires email verification'
};
