import { List, ListProps, Pagination } from 'react-admin';
import { FreepikListDataGrid } from './FreepikListDataGrid';
import { CheckContextProvider } from './CheckContext';

export const FreepikAccountList = (props: ListProps) => (
  <List perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 300]} />} {...props}>
    <CheckContextProvider>
      <FreepikListDataGrid />
    </CheckContextProvider>
  </List>
);
