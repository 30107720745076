import { BooleanInput, Create, CreateProps, NumberInput, SimpleForm, TextInput } from 'react-admin';
import { extractProxyDetails, parseFormattedUrl } from '../../utils/string';

const getRandomValue = () => {
  const min = 100;
  const max = 300;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const DreamstimeAccountCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm sx={{ width: '100%' }}>
      <TextInput sx={{ minWidth: '400px' }} source="name" label="Name" name="name" />
      <TextInput sx={{ minWidth: '400px' }} source="cookies" label="Cookies" name="cookies" />
      <TextInput
        sx={{ minWidth: '400px' }}
        parse={(v) => {
          const newValue = parseFormattedUrl(extractProxyDetails(v)) || v;
          console.log(newValue);
          return newValue;
        }}
        source="proxy_url"
        label="Proxy URL or settings"
        name="proxy_url"
      />
      <TextInput sx={{ minWidth: '400px' }} source="username" label="Username" name="username" />
      <TextInput sx={{ minWidth: '400px' }} source="password" label="Password" name="password" />
      <NumberInput source="upload_limit" name="upload_limit" />
      <BooleanInput defaultValue={true} source="upload_enabled" name="upload_enabled" />
      <NumberInput defaultValue={getRandomValue()} source="upload_limit" name="upload_limit" />
    </SimpleForm>
  </Create>
);
