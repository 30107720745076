import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useCallback } from 'react';
import { useListContext } from 'react-admin';

export const SelectAllCheckbox = () => {
  const { onUnselectItems, selectedIds, sort, data, onSelect, setSort } = useListContext();
  const selectableIds = Array.isArray(data) ? data.map((record) => record.id) : [];

  const handleSelectAll = useCallback(
    (event: any) =>
      onSelect(
        event.target.checked
          ? selectedIds.concat(data.filter((record) => !selectedIds.includes(record.id)).map((record) => record.id))
          : []
      ),
    [data, onSelect, selectedIds]
  );
  return (
    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '3px', borderRadius: '5px' }}>
      <FormControlLabel
        label={
          selectedIds.length > 0 && selectableIds.length > 0 && selectableIds.every((id) => selectedIds.includes(id))
            ? 'Отменить все'
            : 'Выбрать все'
        }
        control={
          <Checkbox
            title="select all"
            aria-label={'select all'}
            className="select-all"
            color="primary"
            checked={
              selectedIds.length > 0 &&
              selectableIds.length > 0 &&
              selectableIds.every((id) => selectedIds.includes(id))
            }
            onChange={handleSelectAll}
          />
        }></FormControlLabel>
    </div>
  );
};
