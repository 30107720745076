import { NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';
import React from 'react';

export const PromptFilterShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="sources" />
      <NumberField source="adobe_sales_count" />
      <TextField source="whitelist_categories" />
      <TextField source="whitelist_regex" />
      <TextField source="blacklist_categories" />
      <TextField source="blacklist_words" />
    </SimpleShowLayout>
  </Show>
);
