import {
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin';

export const PromptShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="prompt" />
      <TextField source="keywords" />
      <TextField source="source" />
      <TextField source="category" />
      <TextField source="title" />
      <TextField source="description" />
      <TextField source="content_type" />
      <NumberField source="adobe_sales_count" />
      <ReferenceField source="parent_prompt_id" reference="prompts">
        <FunctionField
          source=""
          render={(record: any) => `ID: ${record.id}. Text: ${record.prompt}. Keywords: ${record.keywords}\n\n`}
        />
      </ReferenceField>
      <ReferenceArrayField source="children_prompt_ids" reference="prompts">
        <FunctionField
          source=""
          render={(record: any) => `ID: ${record.id}. Text: ${record.prompt}. Keywords: ${record.keywords}\n\n`}
        />
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);
