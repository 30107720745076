import { Admin, EditGuesser, Resource, ShowGuesser } from 'react-admin';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import SchemaIcon from '@mui/icons-material/Schema';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BadgeIcon from '@mui/icons-material/Badge';
import ImageIcon from '@mui/icons-material/Image';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { dataProvider } from './dataProvider';

import { authProvider } from './authProvider';

import {
  AdobeStockAccountCreate,
  AdobeStockAccountEdit,
  AdobeStockAccountList,
  AdobeStockAccountShow
} from './resources/adobeStockAccounts';
import { ImageList } from './resources/images';
import { GeneratorOptionsCreate, GeneratorOptionsList } from './resources/generatorOptions';
import { PromptFilterCreate, PromptFilterEdit, PromptFilterList } from './resources/promptFilters';
import { PipelineCreate, PipelineEdit, PipelineList, PipelineShow } from './resources/pipelines';
import { PromptList, PromptShow } from './resources/prompts';
import { PromptCreate } from './resources/prompts/PromptCreate';
import { PromptFilterShow } from './resources/promptFilters/PromptFilterShow';
import {
  DreamstimeAccountCreate,
  DreamstimeAccountEdit,
  DreamstimeAccountList,
  DreamstimeAccountShow
} from './resources/dreamstimeAccounts';
import {
  FreepikAccountCreate,
  FreepikAccountEdit,
  FreepikAccountList,
  FreepikAccountShow
} from './resources/freepikAccounts';

export const App = () => {
  return (
    <div translate="no">
      <Admin dataProvider={dataProvider} authProvider={authProvider} disableTelemetry>
        <Resource name="images" icon={ImageIcon} list={ImageList} />

        <Resource
          name="adobe_stock_accounts"
          icon={BadgeIcon}
          list={AdobeStockAccountList}
          create={AdobeStockAccountCreate}
          edit={AdobeStockAccountEdit}
          show={AdobeStockAccountShow}
        />
        <Resource
          name="dreamstime_accounts"
          icon={BadgeIcon}
          list={DreamstimeAccountList}
          create={DreamstimeAccountCreate}
          edit={DreamstimeAccountEdit}
          show={DreamstimeAccountShow}
        />
        <Resource
          name="freepik_accounts"
          icon={BadgeIcon}
          list={FreepikAccountList}
          create={FreepikAccountCreate}
          edit={FreepikAccountEdit}
          show={FreepikAccountShow}
        />
        <Resource
          name="generator_options"
          icon={DisplaySettingsIcon}
          list={GeneratorOptionsList}
          create={GeneratorOptionsCreate}
          edit={EditGuesser}
          show={ShowGuesser}
        />
        <Resource
          name="prompt_filters"
          icon={FilterAltIcon}
          list={PromptFilterList}
          create={PromptFilterCreate}
          edit={PromptFilterEdit}
          show={PromptFilterShow}
        />
        <Resource
          name="pipelines"
          icon={SchemaIcon}
          list={PipelineList}
          edit={PipelineEdit}
          show={PipelineShow}
          create={PipelineCreate}
        />
        <Resource
          name="prompts"
          icon={TextFieldsIcon}
          list={PromptList}
          edit={EditGuesser}
          show={PromptShow}
          create={PromptCreate}
        />
      </Admin>
    </div>
  );
};
