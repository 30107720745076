import {
  BooleanField,
  Count,
  Datagrid,
  FunctionField,
  List,
  ListProps,
  NumberField,
  ReferenceField,
  TextField,
  useListContext
} from 'react-admin';
import React from 'react';
import { AdobeAccount } from '../../types/AdobeStockAccount';
import { IPipeline } from '../../types/Pipeline';
import { BulkAccountSelect } from './BulkAccountSelect';
import { CountGenerated } from './CountGenerated';
import { PipelineSwitch } from './PipelineSwitch';

const PipelineDataGrid = (props: any) => {
  const { data } = useListContext<IPipeline>();
  const activePipelineGenerators = data?.filter((pplne) => pplne.enabled && pplne.generator_enabled);
  const totalActiveWeight = activePipelineGenerators?.reduce((weight, p) => {
    return weight + p.weight;
  }, 0);
  const calculateIsActive = (type: 'generator' | 'uploader', record: IPipeline) => {
    const isGenerator = type === 'generator';

    return isGenerator
      ? !record.enabled || !record.generator_enabled
        ? 'opacity-60'
        : ''
      : !record.enabled || !record.adobe_stock_uploader_enabled
      ? 'opacity-60'
      : '';
  };
  return (
    <>
      {' '}
      {/* <AccountSelect source="adobe_stock_account_id"></AccountSelect> */}
      <Datagrid {...props} bulkActionButtons={<BulkAccountSelect></BulkAccountSelect>} rowClick="show">
        <TextField source="id" />
        <FunctionField
          source="generator_enabled"
          render={(record: IPipeline) => {
            return (
              <span className={`relative ${calculateIsActive('generator', record)}`}>
                <PipelineSwitch
                  checked={record.generator_enabled}
                  record={record}
                  diff={{ generator_enabled: !record.generator_enabled }}></PipelineSwitch>
              </span>
            );
          }}></FunctionField>
        <FunctionField
          className="relative"
          source="weight"
          render={(record: IPipeline) => {
            const isActive = record.enabled && record.generator_enabled;
            return (
              <span className={calculateIsActive('generator', record)}>
                {isActive ? (record.weight / totalActiveWeight) * 100 : 0}%
              </span>
            );
          }}></FunctionField>
        <FunctionField
          className="relative"
          source="Uploaded/Ready To Upload/Rejected"
          label="Generated/Rejected/OnReview"
          render={(record: IPipeline) => {
            return (
              <span className={calculateIsActive('generator', record)}>
                <CountGenerated record={record} />/<span>{record.image_stats.status_counts['on-review'] || 0}</span>
              </span>
            );
          }}></FunctionField>
        <FunctionField
          source="limit"
          render={(record: IPipeline) => {
            return (
              <>
                <NumberField className={`relative ${calculateIsActive('generator', record)}`} source="limit" />
              </>
            );
          }}></FunctionField>
        <BooleanField source="adobe_stock_checkbox_ai_generated" />
        <FunctionField
          source="prompt_filter_id"
          render={(record: IPipeline) => {
            return (
              <ReferenceField
                className={`relative ${calculateIsActive('generator', record)}`}
                source="prompt_filter_id"
                reference="prompt_filters">
                <FunctionField source="" render={(record: any) => `${record.name}`} />
              </ReferenceField>
            );
          }}></FunctionField>

        <FunctionField
          source="dreamstime_uploader_enabled"
          render={(record: IPipeline) => {
            return (
              <span className={`relative ${calculateIsActive('uploader', record)}`}>
                <PipelineSwitch
                  checked={record.dreamstime_uploader_enabled}
                  record={record}
                  diff={{ dreamstime_uploader_enabled: !record.dreamstime_uploader_enabled }}></PipelineSwitch>
              </span>
            );
          }}></FunctionField>
        <FunctionField
          source="freepik_uploader_enabled"
          render={(record: IPipeline) => {
            return (
              <span className={`relative ${calculateIsActive('uploader', record)}`}>
                <PipelineSwitch
                  checked={record.freepik_uploader_enabled}
                  record={record}
                  diff={{ freepik_uploader_enabled: !record.freepik_uploader_enabled }}></PipelineSwitch>
              </span>
            );
          }}></FunctionField>
        <FunctionField
          source="adobe_stock_uploader_enabled"
          render={(record: IPipeline) => {
            return (
              <span className={`relative ${calculateIsActive('uploader', record)}`}>
                <PipelineSwitch
                  checked={record.adobe_stock_uploader_enabled}
                  record={record}
                  diff={{ adobe_stock_uploader_enabled: !record.adobe_stock_uploader_enabled }}></PipelineSwitch>
              </span>
            );
          }}></FunctionField>
        <FunctionField
          className={`relative `}
          source="adobe_stock_account_id"
          render={(record: IPipeline) => {
            return record.adobe_stock_auto_upload ? (
              <span className={calculateIsActive('uploader', record)}>Auto ⚡</span>
            ) : (
              <>
                <ReferenceField
                  className={calculateIsActive('uploader', record)}
                  source="adobe_stock_account_id"
                  reference="adobe_stock_accounts">
                  <FunctionField source="" render={(record: AdobeAccount) => `${record.id}`} />
                </ReferenceField>
              </>
            );
          }}></FunctionField>

        <ReferenceField
          className="relative"
          label="Upload batch size"
          source="adobe_stock_account_id"
          reference="adobe_stock_accounts">
          <NumberField source="upload_batch_size" />
        </ReferenceField>
        <FunctionField
          className="relative"
          label="Ready/Uploaded/AU"
          render={(record: IPipeline) => {
            return (
              <span className={calculateIsActive('uploader', record)}>
                <span>{record.image_stats.status_counts['review-approved'] || 0}</span>/
                <span>{record.image_stats.status_counts['uploaded-to-stock'] || 0}</span>/
                <Count
                  className="text-green-500"
                  resource="images"
                  filter={{
                    status: 'uploaded-to-stock',
                    pipeline_id: record.id,
                    adobe_stock_account_id: record.adobe_stock_account_id
                  }}></Count>
              </span>
            );
          }}></FunctionField>
      </Datagrid>
    </>
  );
};

export const PipelineList = (props: ListProps) => {
  return (
    <List {...props}>
      <PipelineDataGrid></PipelineDataGrid>
    </List>
  );
};
