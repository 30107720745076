import { Button, Card, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useCallback } from 'react';
import {
  Count,
  Filter,
  FilterForm,
  FilterList,
  FilterListItem,
  SelectInput,
  useGetList,
  useListContext
} from 'react-admin';

import { SelectAllCheckbox } from './SelectAllCheckbox';

const PipelineFilter = (props: any) => {
  // Fetch the pipelines data using the useListContext hook
  const { data: pipelines, error: pipelinesError, isLoading: pipelinesLoading } = useGetList('pipelines');
  if (pipelinesLoading || pipelinesError) {
    return <span>loading</span>; // or display a loading spinner or an error message
  } // Generate the filter options dynamically
  const choices = pipelines ? pipelines.map((pipeline) => ({ id: pipeline.id, name: pipeline.id })) : [];

  return (
    <Filter {...props}>
      <SelectInput
        source="pipeline_id"
        label="Pipeline"
        choices={choices}
        optionText="name"
        optionValue="id"
        alwaysOn
      />
    </Filter>
  );
};

const imageFilters = [
  <PipelineFilter alwaysOn key={'is gpt smart or govno'}></PipelineFilter>,

  // @ts-ignore
  <FilterList sx={{ display: 'flex' }} key={'filter'} alwaysOn icon={''} label="">
    <FilterListItem key={1} label="На проверку" value={{ status: 'on-review' }} defaultChecked={true} />
    <FilterListItem key={2} label="Одобренные" value={{ status: 'review-approved' }} />
    <FilterListItem key={3} label="Отклоненные" value={{ status: 'review-rejected' }} />
  </FilterList>
];

export const ImageListToolbar = () => {
  const { onUnselectItems, selectedIds, sort, data, onSelect, setSort } = useListContext();

  const handleUnselectAllClick = useCallback(() => {
    onUnselectItems();
  }, [onUnselectItems]);

  return (
    <Stack sx={{ marginTop: '20px', gap: '10px' }} direction="row" justifyContent="space-between">
      <div>
        <Button
          onClick={() => {
            setSort({ field: 'pipeline_id', order: 'DESC' });
          }}
          startIcon={
            sort.order === 'DESC' && sort.field === 'pipeline_id' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />
          }>
          {' '}
          Sort by pipeline ID
        </Button>
        <Button
          onClick={() => {
            setSort({ field: 'updated_at', order: 'DESC' });
          }}
          startIcon={
            sort.order === 'DESC' && sort.field === 'updated_at' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />
          }>
          {' '}
          Sort by date
        </Button>
        <SelectAllCheckbox></SelectAllCheckbox>
        {selectedIds.length ? (
          <div style={{ width: '200px', display: 'flex', alignItems: 'start' }}>
            <Button color="secondary" variant="outlined" onClick={() => handleUnselectAllClick()}>
              <CloseIcon /> {selectedIds.length} selected
            </Button>
          </div>
        ) : (
          <div className="h-9 w-2"></div>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Card sx={{ padding: '5px' }}>
          <Typography>Проверено:</Typography>
          <Count filter={{ status: 'review-approved' }}></Count>
          <Typography>Осталось на проверку:</Typography>
          <Count filter={{ status: 'on-review' }}></Count>
        </Card>
        <FilterForm filters={imageFilters} />
      </div>
    </Stack>
  );
};
