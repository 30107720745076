import { Switch } from '@mui/material';

import { useNotify, useUpdate } from 'react-admin';
import { IPipeline } from '../../types/Pipeline';

interface PipelineSwitchProps {
  record: IPipeline;
  diff: Partial<IPipeline>;
  checked: boolean;
}

export const PipelineSwitch = (props: PipelineSwitchProps) => {
  const [update, { isLoading, error }] = useUpdate<IPipeline>();
  const notify = useNotify();
  const handleChange = () => {
    update(
      'pipelines',
      {
        id: props.record.id,
        data: { ...props.record, ...props.diff },
        previousData: props.record
      },
      {
        onSuccess: () => {
          notify('Updated');
        },
        onError: () => {
          notify('Update failed', { type: 'error' });
        },
        mutationMode: 'optimistic'
      }
    );
  };
  return (
    <Switch
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={handleChange}
      checked={props.checked}></Switch>
  );
};
