import ErrorIcon from '@mui/icons-material/Error';
import { CircularProgress, Typography } from '@mui/material';
import { useGetList } from 'react-admin';
import { IPipeline } from '../../types/Pipeline';

interface CountGeneratedProps {
  record: IPipeline;
}

export const CountGenerated = (props: CountGeneratedProps) => {
  const pipeline = props.record;
  const onReview = pipeline.image_stats.status_counts['on-review'] || 0;
  const approved = pipeline.image_stats.status_counts['review-approved'] || 0;
  const uploaded = pipeline.image_stats.status_counts['uploaded-to-stock'] || 0;
  const rejected = pipeline.image_stats.status_counts['review-rejected'] || 0;

  const generated = onReview + approved + uploaded + rejected;
  const rejectedPercent = ((rejected / (approved + uploaded + rejected || 1)) * 100).toFixed(2) + '%';
  return (
    <>
      <Typography component="span" variant="body2">
        {generated}/{rejected}({rejectedPercent})
      </Typography>
    </>
  );
};
