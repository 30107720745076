import { useListContext, useNotify, useRefresh, useUnselectAll, useUpdateMany } from 'react-admin';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { IImage } from '../../types/Image';

interface RejectButtonProps {
  width?: string;
}

export const RejectImagesButton = (props: RejectButtonProps) => {
  const { width = '270px' } = props;
  const { selectedIds } = useListContext<IImage>();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('images');
  const [updateMany, { isLoading }] = useUpdateMany<IImage>(
    'images',
    { ids: selectedIds, data: { status: 'review-rejected' } },
    {
      onSuccess: () => {
        console.log('success');
        refresh();
        notify('Images updated');
        unselectAll();
      },
      onError: (error) => notify('Error: images not updated', { type: 'error' })
    }
  );

  return (
    <Button sx={{ width }} color="error" variant="contained" disabled={isLoading} onClick={() => updateMany()}>
      <Close />
      <span className="text-xs">
        Отклонить все <span className="text-base font-semibold">{selectedIds.length}</span> выбранные
      </span>
    </Button>
  );
};
