// works for this format "http://192.168.0.1:8000:login:password"
export function extractProxyDetails(str: string) {
  if (str.includes('@')) {
    let protocol = 'http';
    let username = '';
    let password = '';
    let proxyUrl = '';

    // Extract protocol
    const protocolIndex = str.indexOf('://');
    if (protocolIndex !== -1) {
      protocol = str.split('://')[0];
      str = str.substring(protocolIndex + 3);
    }

    // Extract username and password
    if (str.indexOf('@') !== -1) {
      const credentialsAndIp = str.split('@');
      const userPass = credentialsAndIp[0].split(':');
      proxyUrl = credentialsAndIp[1] ?? '';
      username = userPass[0] ?? '';
      password = userPass[1] ?? '';
    }
    return {
      protocol: protocol.trim(),
      username: username.trim(),
      password: password.trim(),
      url: proxyUrl.trim()
    };
  } else {
    // works for this format "http://192.168.0.1:8000:login:password"
    let protocol = 'http';
    const protocolIndex = str.indexOf('://');
    if (protocolIndex !== -1) {
      protocol = str.split('://')[0];
      str = str.substring(protocolIndex + 3);
    }
    const parts = str.split(':');

    // Extract the protocol, username, password, and URL

    const username = parts[2] ?? '';
    const password = parts[3] ?? '';
    const url = parts[0] + ':' + parts[1];

    // Return the extracted details as an object
    return {
      protocol: protocol.trim(),
      username: username.trim(),
      password: password.trim(),
      url: parts[1] ? url.trim() : ''
    };
  }
}

export const parseFormattedUrl = (data: { protocol: string; username: string; password: string; url: string }) => {
  console.log(data);
  if (!data.url || !data.password || !data.username) return '';
  return `${data.protocol}://${data.username}:${data.password}@${data.url}`;
};
