import jsonServerProvider from 'ra-data-json-server';

import { fetchUtils } from 'react-admin';
import { authProvider } from './authProvider';

export const API_URL = process.env.REACT_APP_AISTOCKER_URL || 'https://aistocker.juicedelivery.xyz';
const localToken: string | undefined = undefined;
const fetchJson = async (url: string, options: any = {}) => {
  const token = localToken ?? (await authProvider.getJWTToken());
  options.user = {
    authenticated: true,
    // use the token from local storage
    token: token
  };
  return fetchUtils.fetchJson(url, options);
};
export const dataProvider = jsonServerProvider(API_URL, fetchJson);
