import { ImageStatus } from './Image';

export interface IPipeline {
  id: string;

  enabled: boolean;
  weight: number;
  limit: number;

  prompt_filter_id: string;

  resize_enabled: boolean;
  resize_width: number;
  resize_thumbnail_width: number;

  manual_review_enabled: boolean;

  adobe_stock_uploader_enabled: boolean;
  adobe_stock_account_id: string;
  adobe_stock_auto_upload?: boolean;

  image_stats: { status_counts: Partial<Record<ImageStatus, number>> };

  adobe_stock_checkbox_ai_generated: boolean;
  adobe_stock_image_extra_title: string;
  adobe_stock_content_type: ContentType;

  dreamstime_uploader_enabled: boolean;
  freepik_uploader_enabled: boolean;

  generator_enabled: boolean;
  generator_options_id: string;
}

export enum ContentType {
  UNDEFINED = 0,
  Photos = 1,
  Illustrations = 2,
  Vectors = 3,
  Videos = 4
}
export const ContentTypeMap = [undefined, 'Photos', 'Illustrations', 'Vectors', 'Videos'];
