import { BooleanField, FunctionField, NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { FreepikAccount } from '../../types/FreepikAccount';

export const FreepikAccountShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField sx={{ minWidth: '400px' }} source="id" />
      <TextField sx={{ minWidth: '400px' }} source="name" />
      <TextField sx={{ minWidth: '400px' }} source="upload_status_message" />
      <FunctionField
        sx={{ minWidth: '400px' }}
        label="Cookies"
        render={(record: FreepikAccount) => record.cookies.slice(0, 100) + '...'}
      />
      <BooleanField source="upload_enabled" />
      <NumberField source="upload_limit" />
      <TextField sx={{ minWidth: '400px' }} source="proxy_url" />
      <TextField sx={{ minWidth: '400px' }} source="username" />
      <TextField sx={{ minWidth: '400px' }} source="password" />
    </SimpleShowLayout>
  </Show>
);
