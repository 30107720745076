export interface AdobeAccount {
  id: string;

  dolphin_profile_id: string | null;
  dolphin_profile_name: string | null;
  csrf_token: string;
  cookies: string;
  headers_update_datetime?: number;
  sales_update_date?: number;
  upload_limit: number;

  upload_batch_size: number;
  auto_upload_enabled?: boolean;
  auto_upload_limit?: number;
  upload_paused_until?: number;
  upload_failure_counter: number;
  proxy_url: string;

  status_message?: keyof typeof AdobeStockAccountStatusMapping;

  username?: string;
  password?: string;
}

export const AdobeStockAccountStatusMapping = {
  // OK: 'целковый',
  // 'auto upload limit reached': 'порнушка',
  // 'upload limit reached': 'чекушка',
  // 'submit limit reached': 'хуй на воротничок',
  // 'proxy error': 'засирушка',
  // 'invalid csrf token error': 'жучок',
  // 'invalid cookie error': 'мудачок',
  // 'unknown upload error': 'пердушка'
  OK: '🆗',
  'auto upload limit reached': '🏁 auto upload limit reached',
  'upload limit reached': '🏁 upload limit reached',
  'submit limit reached': '💤 submit limit reached',
  'proxy error': '❌🛡️ proxy error',
  'invalid csrf token error': '❗👩‍🦽👦🏾 invalid csrf token error',
  'invalid cookie error': '❗👩‍🦽🍪 invalid cookie error',
  'unknown upload error': '🚨 unknown upload error',
  'requires email verification': '📩 requires email verification'
};
