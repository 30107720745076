import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const AdobeStockAccountEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput sx={{ minWidth: '400px' }} source="id" />
      <TextInput sx={{ minWidth: '400px' }} source="dolphin_profile_id" />
      <TextInput sx={{ minWidth: '400px' }} source="region" />
      <TextInput sx={{ minWidth: '400px' }} source="csrf_token" />
      <TextInput sx={{ minWidth: '400px' }} source="cookies" />
      <NumberInput source="upload_limit" />
      <NumberInput source="upload_batch_size" />
      <BooleanInput sx={{ minWidth: '400px' }} source="auto_upload_enabled" />
      <NumberInput sx={{ minWidth: '400px' }} source="auto_upload_limit" />
      <TextInput sx={{ minWidth: '400px' }} source="proxy_url" />
      <TextInput sx={{ minWidth: '400px' }} source="username" />
      <TextInput sx={{ minWidth: '400px' }} source="password" />
    </SimpleForm>
  </Edit>
);
