import { Card, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useEffect, useRef } from 'react';
import union from 'lodash/union';
import difference from 'lodash/difference';
import {
  FilterForm,
  FunctionField,
  List,
  Pagination,
  RecordContextProvider,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  useListContext
} from 'react-admin';
import { IImage } from '../../types/Image';
import { ApproveImagesButton } from './ApproveImagesButton';
import css from './Image.module.scss';
import { ImageCard } from './ImageCard';
import { ImageListToolbar } from './ImageListToolbar';
import { RejectImagesButton } from './RejectImagesButton';
import { SelectAllCheckbox } from './SelectAllCheckbox';

const ImagePagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 300]} />;

const ImageFilter = (props: any) => (
  <FilterForm {...props}>
    <ReferenceInput source="pipeline_id" reference="pipelines">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </FilterForm>
);

const ImageGrid = () => {
  const { data, isLoading, selectedIds, onSelect, onToggleItem } = useListContext<IImage>();
  const lastSelected = useRef<string | null>(null);

  useEffect(() => {
    if (!selectedIds || selectedIds.length === 0) {
      lastSelected.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedIds)]);

  const handleShiftToggleItem = useCallback(
    (id: string, event: any) => {
      const ids = data.map((record) => record.id);
      const lastSelectedIndex = ids.indexOf(lastSelected.current ?? '');

      lastSelected.current = event.target.checked ? id : null;

      if (lastSelectedIndex !== -1) {
        const index = ids.indexOf(id);
        const idsBetweenSelections = ids.slice(
          Math.min(lastSelectedIndex, index),
          Math.max(lastSelectedIndex, index) + 1
        );

        const newSelectedIds = event.target.checked
          ? union(selectedIds, idsBetweenSelections)
          : difference(selectedIds, idsBetweenSelections);
        onSelect(newSelectedIds);
      } else {
        onToggleItem(id);
      }
    },
    [data, onToggleItem, onSelect, selectedIds]
  );
  if (isLoading) {
    return null;
  }
  return (
    <div translate="no">
      <ImageListToolbar />
      <div className={clsx(css.toolbar)}>
        <RejectImagesButton></RejectImagesButton>
        <ApproveImagesButton></ApproveImagesButton>
        <SelectAllCheckbox></SelectAllCheckbox>
      </div>

      <Grid container spacing={3} sx={{ marginTop: '1em' }}>
        {data?.map((record) => (
          <RecordContextProvider key={record.id} value={record}>
            <Grid key={record.id} md={4} item>
              <Card>
                <ImageCard handleShiftToggleItem={handleShiftToggleItem} record={record}></ImageCard>
                <Typography sx={{ marginLeft: '7px' }} variant="subtitle2">
                  {new Date(record.updated_at * 1000).toLocaleString()}
                </Typography>
                <TextField sx={{ paddingLeft: '7px' }} source="pipeline_id" />
                {/* <ReferenceField label="Pipeline" source="pipeline_id" reference="pipelines">
                  <TextField sx={{ paddingLeft: '7px' }} source="pipeline_id" />
                </ReferenceField> */}
                <FunctionField
                  sx={{ paddingLeft: '14px', fontSize: '14px' }}
                  label="Discord Url"
                  render={(record: IImage) => (
                    <>
                      <a target={'_blank'} rel="noreferrer" href={record.generator_image_url}>
                        Full
                      </a>
                      <a className="m-2" target={'_blank'} rel="noreferrer" href={record.image_url}>
                        JPGfull
                      </a>
                    </>
                  )}
                />
                <div style={{ display: 'none' }}>
                  <TextField source="prompt" />
                </div>
              </Card>
            </Grid>
          </RecordContextProvider>
        ))}
      </Grid>
    </div>
  );
};

export const ImageList = (props: any) => {
  return (
    <List
      {...props}
      filterDefaultValues={{ status: 'on-review' }}
      perPage={100}
      component="div"
      actions={false}
      pagination={<ImagePagination />}>
      <ImageGrid />
    </List>
  );
};
